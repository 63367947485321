.iconredes{
    height: 35px;
}
.secciones{
    padding-top: 20px!important;
  }
  .iconcontact{
    height: 55px;
  }
  .btnHome{
        position: sticky;
        z-index: 9;
        height: 23px;
  }

  .cmn-divfloat {
    position: fixed !important;
    bottom: 45px;
    right: 15px;
    z-index: 9;
}


.cmn-btncircle {
    height: 50px !important;
    border-radius: 50%;
    font-size: 18px;
    text-align: center;
}

