:root{

  --blue: #037dce;
  --black: black;
  --white: rgb(210, 210, 210);

}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emmaTitulo{
  font-family: "Gelasio", serif;

font-size: 2rem !important;

}

.emmalogoL {
  padding-left: 10px;
  font-family: "Gelasio", serif;
  color: rgb(0, 156, 223) !important;
  font-size: 1.8rem !important;
  text-shadow: 1px 1px 2px #00477d, 0 0 1em rgb(162, 204, 252);
  text-decoration: none;
}
.emmalogoD{
  padding-left: 10px;
  font-family: "Gelasio", serif;
  color: rgb(157, 226, 255) !important;
  font-size: 1.8rem !important;
  text-shadow: 1px 1px 2px #00477d, 0 0 1em rgb(102, 144, 192);
  text-decoration: none;
}
.canvas {
  background-color: #a3d7ff !important;
}

header {

  background-color: #6897BD!important;
  position: fixed !important;
  z-index: 9 !important;
  width: 100% !important;
}

.seccion {
  padding-top: 95px!important;
}

.iconMode {
  padding-left: 14px;
  height: 20px;
}
.navBarLight{
  background-color: #9ddbff!important;
}
.navBarDark{
  background-color: #004A83!important;
  color: #c7e1f5 !important;
}

.darkModeP {
  background-color: #00355e!important;
  color: #c7e1f5 !important;
}
.lightModeP {
  background-color: #cee9f9!important;

  color: #001c31 !important;
}
.texColor {
  color: #def1ff !important;
}

.darkModeS {
  background-color: #004a83 !important;
  color: #ffffff !important;
}
.lightModeS {
  background-color: #b3e0f5 !important;
  color: #244258 !important;
}


.btn-ghost{
  position: relativate;
  display: inline-block;
  border: 2px solid var(--white);
  padding: 5px 10px;
  margin: 25px 5px;
  color: var(--black);
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  background: var(--white);
  transition: color 0.5s, background 0.5s;
  margin-bottom: 15px;
  font-size: 0.8rem;
  min-width: 140px;
  text-align: center;
}
.btn-ghost.round{
  border-radius: 10px;
}


.btn-ghost.secundary{
  background-color: transparent;
  color: var(--white);
}

.btn-ghost:hover{
  color: var(--white);
  background-color: transparent;
}

.btn-ghost.secundary:hover{
  color: var(--black);
  background-color: var(--white);
}

.btn-ghost:active{
  box-shadow: inset 0 0 20px var(--white);
}

.btn-ghost.secundary:active{
  box-shadow: inset 0 0 20px var(--black);
}


/** BLUE */
.btn-ghost.blue{
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.btn-ghost.secundary.blue{
  background-color: transparent;
  color: var(--blue);
}

.btn-ghost.blue:hover{
  background-color: transparent;
  color: var(--blue);
}

.btn-ghost.secundary.blue:hover{
  background-color: var(--blue);
  color: var(--white);
}

.btn-ghost.blue:active{
  box-shadow: inset 0 0 20px var(--blue);
}

.btn-ghost.secundary.blue:active{
  box-shadow: inset 0 0 20px var(--white);
}

.logoMore{
  border-radius: 22px;
  border: 12px double;
  height: 100px;
  padding: 9px 0px;
}

.imgMore{
  border-radius: 25px;
  opacity: 0.87;
  height: 400px!important;
  width: auto!important;
  z-index: 0;
}
@media (max-width: 768px) {
  .emmalogo {
    font-size: 1.2rem !important;
  }
  .seccion {
    padding-top: 95px !important;
  }
  .footer{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    padding-top: 20px;
    font-size: 12px!important;
    height: 100px!important;
  }
  .iconcontact{
    height: 35px!important;
  }
}