.contvideo {
  position: relative !important;
}
.video {
  position: relative !important;
  width: 100% !important;
  height: calc(100vh - 70px) !important;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}




.cardvideo {
  position: absolute !important;
  margin-top: 400px !important;
  z-index: 3;
  width: auto;
}
#vidCard {
  border-radius: 4px;
  height: 100px;
  width: 40vw !important;
}
#LogoSlogan {
  font-size: 1.5rem;
  font-family: "Gelasio", serif;
}
#Slogan{
  font-size: 1rem;
  font-family: "Gelasio", serif;
}

@media (max-width: 768px) {
  .contvideo {
    height: 90vh!important;
}
  .video {
    height: 100% !important;
  }

  #vidCard {
    width: 90vw !important;
  }

  .cardvideo {
    margin-top: 400px !important;
  }

 
}
