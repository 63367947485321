.carruselimage{
    height: 300px;
    text-align: center;
}

.carousel-control-icon{
height: 45px;
}
.emmaText {
    font-family: "Gelasio", serif;
    font-size: 1rem;
    text-decoration: none;
  }

@media (max-width: 768px) {
    .carruselimage{
        height: 200px;
        text-align: center;
    }
    }