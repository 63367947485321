.card:hover {
  box-shadow: 12px 10px 20px 2px rgba(19, 11, 11, 0.273) !important;
  transition: all 0.7s linear;
}
.card-img-top {
  transition: all 0.2s ease-in-out;
}
.card-img-top:hover {
  transform: scale(1.2);
}
.overflow {
  overflow: hidden;
}

.textCurs {
  color: #002a5a !important;
}

.imgCompcur > img {
  width: 300px;
}
.imgdetail {
  width: 100%;
  border: 3px solid #dddddd9f;
  border-radius: 10px;
}
.imgcurso {
  margin-top: 60px;
  height: 170px;
  width: auto;
}
.modcurso {
  border-radius: 10px 10px 0 0;
  position: absolute;
  padding-top: 10px;
  z-index: 3;
  width: 100%;
}

.detailsD {
  border-left-style: solid;
  padding-left: 5px;
  width: 2px;
  border-color: rgb(255, 255, 255);
}
.detailsL {
  border-left-style: solid;
  padding-left: 5px;
  width: auto;
  border-color: rgb(40, 40, 40);
}
.iconcur {
  height: 17px;
  padding-right: 4px;
}
.iconcur2 {
  height: 25px;
  padding-right: 4px;
}
.imgtarget{
  width: 200px;
  border-radius: 10px;
  border-color: rgb(190, 243, 255);
  background: linear-gradient(0.15turn,rgb(123, 208, 236),rgb(5, 56, 90));
}
.bold {
  font-weight: bold;
}

.bgp {
  background-color: #000 !important;
}
.img-person {
  height: 250px;
  border: 6px solid #ffffff;
  border-radius: 25px;
  background: linear-gradient(0.95turn,rgb(123, 208, 236),rgb(5, 56, 90));

}
.justify{
  text-align: justify;
}
@media (max-width: 768px) {
  .imgCompcur > img {
    width: 300px;
  }
}
